<template>
  <div class="d-flex justify-content-between">
    <div>
      <div class="reviewed-star star">
        <star-rating :rating="Number(rate.total_rate)" :star-size="22" :show-rating="false" :read-only="true" />
        {{ _totalRate(rate.total_rate) }}
        ({{ rate.amount }})
      </div>
    </div>
    <div :class="`btn-fav ${this.getLessonDetail.is_favorite ? 'favorited' : ''}`" role="button" @click="handleClick">
      <i class="fa fa-heart-o" aria-hidden="true"></i>
      {{ $t("lesson.detail.favorite") }}{{ this.favoriteCount }}{{ $t("lesson.detail.people") }}
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      addFavoriting: false
    }
  },
  props: {
    rate: Object,
    favoriteCount: Number,
    lesson_id: Number,
  },
  computed: {
    ...mapGetters({ getLessonDetail: "lessonDetail/getLessonDetail" }),
  },
  methods: {
    ...mapActions({
      actionFetchLessonDetail: "lessonDetail/fetchLessonDetail",
      actionSetPopup: "lessonDetail/setPopup",
      actionUpdateFavorite: "lessonDetail/updateFavorite",
    }),
    _totalRate(rate) {
      rate = rate.toString().slice(0, 3);
      return rate;
    },
    async handleClick() {
      const memberId = this.getMemberId();
      if (memberId) {
        if (this.addFavoriting) return;
        this.addFavoriting = true;
        let response = await this.actionUpdateFavorite({
          parent_id: this.lesson_id,
          member_id: memberId,
        });
        if (response.data) {
          let lessonId = this.$router.currentRoute.params.lessonId;
          const memberId = this.getMemberId();
          await this.actionFetchLessonDetail({
            lessonId: lessonId,
            member_id: memberId,
          });
        }
        this.addFavoriting = false;
      } else {
        this.actionSetPopup();
      }
    },
  },
};
</script>

<style scoped>
.bi-heart-fill {
  color: red;
  cursor: pointer;
}
</style>