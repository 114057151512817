<template>
    <div class="container-lesson lesson lesson-related" id="card_size" ref="card_size">
        <h5 class="title">{{ $t("lesson.related") }}</h5>
        <carousel
            :items="4"
            :stagePadding="140"
            :margin="28"
            :navText="navMenu"
            :responsive="slideResponsive"
            :dots="false"
            :slideBy="4"
            :rewind="false"
            v-if="items.length > 0"
        >
            <template>
                <router-link
                    :to="{ name: 'lesson.detail', params: { lessonId: item.id } }"
                    class="no-decoration"
                    v-for="(item, key) in items"
                    :key="key"
                >
                    <div class="cimg">
                        <img
                            :src="item.thumbnail"
                            :alt="item.thumbnail"
                            class="bd-placeholder-img bd-placeholder-img-lg d-block w-100"
                            :title="item.title"
                        />
                    </div>
                    <div class="description text-center">
                        <h5 class="custom-text-overflow mt-2">{{ item.title }}</h5>
                        <div class="introduction custom-text-overflow" style="padding-right: 18px">
                            {{ item.teacher_name }}
                        </div>
                        <div class="price text-left">
                            {{ formatCurrency(item.currency_code, item.price) }} {{ $t("lesson.included_tax") }}
                        </div>
                        <div class="reviewed-star rate text-left">
                            <span class="star">
                                <star-rating
                                    :rating="Number(item.rated)"
                                    :star-size="18"
                                    :show-rating="false"
                                    :read-only="true"
                                />
                            </span>
                            <span>
                                {{ item.rated }}
                                {{ item.reviewed ? `(${item.reviewed})` : `` }}
                            </span>
                        </div>
                    </div>
                </router-link>
            </template>
        </carousel>
    </div>
</template>
<script>
import carousel from "vue-owl-carousel";

export default {
    name: "RelatedLesson",
    components: {
        carousel,
    },
    props: {
        items: [],
    },
    data() {
        return {
            navMenu: [
                '<span class="icon">&#8592;</span>',
                '<span class="icon">&#8594;</span>',
            ],
            slideResponsive: {
                0: {
                    items: 2,
                    stagePadding: 15,
                    margin: 20,
                },
                768: {
                    items: 2,
                    stagePadding: 30,
                    margin: 25,
                },
                1024: {
                    items: 3,
                    stagePadding: 40,
                    margin: 25,
                },
                1200: {
                    items: 4,
                    stagePadding: 50,
                    margin: 28,
                },
            }
        };
    },
};
</script>
<style scoped >
    .custom-text-overflow {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
        line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .slide-control {
        width: 50px;
        height: 50px;
        border: 1px solid orange;
        border-radius: 50%;
        position: absolute;
        right: 17%;
        top: 62%;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .control-icon {
        color: orange;
        font-size: 1.5rem;
        font-weight: bolder;
        position: relative;
        top: 14%;
        left: 0;
    }
    .slide-control::after {
        color: orange;
    }

    .slide-control:hover {
        cursor: pointer;
    }
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (max-width: 1200px) {
        .slide-control {
            right: 2%;
            top: 45%;
        }
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (max-width: 992px) {
        .slide-control {
            right: 1%;
            top: 45%;
        }
    }

    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (max-width: 600px) {
        .slide-control {
            right: 1%;
            top: 70.1%;
        }
        .control-icon {
            top: 7%;
        }
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
    }
</style>